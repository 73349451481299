<template>
  <transition-group
    name="fade"
    tag="div"
    class="common-toast-container position-fixed top-0 end-0 px-4 px-md-8"
  >
    <common-notifications-toast
      v-for="n in notifications"
      :key="n.id"
      class="mt-4"
      :title="n.title"
      :text="n.message"
      :type="n.type"
      :timeout="n.timeout"
      @dismiss-notification="handleDismissNotification(n)"
    />
  </transition-group>
</template>

<script setup lang="ts">
import type { Notification } from '~/store/notifications';

const { notifications, removeNotification } = useNotifications();

const handleDismissNotification = async (notification: Notification) => {
  if (notification.dismissedCallback != null) {
    await notification.dismissedCallback();
  }
  removeNotification(notification);
};
</script>
<style scoped lang="scss"></style>

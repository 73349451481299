<template>
  <div
    ref="toastContainer"
    class="common-toast px-8 pt-4 py-6 rounded position-relative"
    :class="{
      'bg-primary text-black': type === 'success',
      'bg-danger text-white': type === 'error',
      'bg-warning text-white': type === 'warning',
    }"
  >
    <strong class="fs-5">{{ title }}</strong>
    <p v-if="text != null && text.length > 0" class="mb-0">
      {{ text }}
    </p>

    <button
      type="button"
      class="btn btn-transparent position-absolute top-0 end-0 me-2"
      aria-label="Close"
      @click="closeToast"
    >
      <fa-icon
        :icon="faTimes"
        class="fs-5"
        :class="{
          'text-black': type === 'success',
          'text-white': type !== 'success',
        }"
      />
    </button>
    <div
      v-if="timeout > 0"
      class="common-toast__progress position-absolute bottom-0 start-0 end-0 rounded-bottom"
      :class="`common-toast__progress--${type}`"
      :style="{ width: `${timeoutProgress * 100}%` }"
    ></div>
  </div>
</template>

<script lang="ts">
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import type { NotificationTypes } from '~/store/notifications';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String as PropType<NotificationTypes>,
      default: 'success',
    },
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  emits: ['dismiss-notification'],
  setup(props, { emit }) {
    const closeToast = () => {
      emit('dismiss-notification');
    };

    const toastContainer = ref<HTMLDivElement>(null);

    const dismissEnd = ref<number>(0);
    const timeoutProgress = ref<number>(1);
    const timeLeft = ref<number>(props.timeout);
    const timeoutId = ref<number>(0);

    // for calculation of progress
    const { pause, resume } = useRafFn(
      () => {
        timeLeft.value = dismissEnd.value - new Date().getTime();
        timeoutProgress.value = 1 - timeLeft.value / props.timeout;
      },
      { immediate: false },
    );

    const stopAutoDismiss = () => {
      clearTimeout(timeoutId.value);
      pause();
    };

    // actual
    const handleAutoDismiss = () => {
      if (timeLeft.value > 0) {
        dismissEnd.value = new Date().getTime() + timeLeft.value;
        resume();
        timeoutId.value = window.setTimeout(() => {
          pause();
          timeoutProgress.value = 0;
          dismissEnd.value = 0;
          emit('dismiss-notification');
        }, timeLeft.value);
      } else {
        stopAutoDismiss();
      }
    };

    // handle hover over toast
    const { isOutside } = useMouseInElement(toastContainer);
    const isOutSideWatchHandler = watch(
      () => isOutside.value,
      (newValue) => {
        if (newValue) {
          handleAutoDismiss();
        } else {
          stopAutoDismiss();
        }
      },
    );

    onMounted(() => {
      handleAutoDismiss();
    });

    onUnmounted(() => {
      isOutSideWatchHandler();
      stopAutoDismiss();
    });

    return {
      faTimes,

      toastContainer,
      closeToast,
      timeoutProgress,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'gportal-theme/scss/colors.scss';

.common-toast {
  &__progress {
    opacity: 0.5;
    height: 0.5rem;

    &--success {
      background: darken($primary, 40%);
    }

    &--warning {
      background: darken($warning, 40%);
    }

    &--error {
      background: darken($danger, 40%);
    }
  }
}
</style>
